import * as React from "react";
import { PageProps } from "gatsby";
import Button, { ButtonProps } from "@/components/Button/button";
import "../../App.scss";
import "@/components/Button/button.scss";
import "@/components/ThemeLifeSpan/ThemeLifeSpan.scss";
import { Link } from "gatsby";
import Title from "@/components/Title/Title";
import Col from "@/components/Col/Col";
import CtaBar from "@/components/CtaBar/CtaBar";
import Section from "@/components/Section/Section";
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import CustomRange from "@/components/CustomRange/CustomRange";
import ThemeLifeSpan from "@/components/ThemeLifeSpan/ThemeLifeSpan";
import ThemeDesktop from "@/components/ThemeDesktop/ThemeDesktop";

import logo_1 from "@/img/logos/logo_1.jpg";
import logo_2 from "@/img/logos/logo_2.jpg";
import logo_3 from "@/img/logos/logo_3.jpg";
import logo_4 from "@/img/logos/logo_4.jpg";
import logo_5 from "@/img/logos/logo_5.jpg";
import logo_6 from "@/img/logos/logo_6.jpg";

import icoChart from "@/img/ico/noAttribute.svg";
import icoCheck from "@/img/ico/noRely.svg";
import hand from "@/img/ico/hand.svg";

import ad_revenue from "@/img/accurateTracking.png";
import integrateTheme from "@/img/integrateTheme.svg";
import fastStart from "@/img/fastStart.svg";
import attributionWindow from "@/img/attributionWindow.png";
import ad_revenue2 from "@/img/revenueTheme2.svg";
import ad_revenue3 from "@/img/revenueTheme3.svg";
import ad_revenue4 from "@/img/revenueTheme4.svg";
import ad_revenue5 from "@/img/revenueTheme5.svg";
import supportedPlatforms from "@/img/supportedPlatforms.svg";

import supportedAds from "@/img/ads.svg";
import supportedNew from "@/img/new.svg";

import phone from "@/img/phone2.png";
import desktop from "@/img/desktop.png";
import shopify__card__icon from "@/img/ico/workspaceDesktop/iconsProviders.svg";

const Home = () => (
  <main>
    <Header homePage></Header>

    <Section className="hero Homepage__hero">
      <div className="hero__headline">
        <h1>
          Get accurate data and scale your ecommerce&nbsp;revenue{" "}
          <span className="violet">fast with certainty</span>.
        </h1>
        <p className="testimonial__logos__description">
          Ecomtrack allows you to track ads revenue with accuracy so you can
          scale your store faster.
        </p>

        <div className="testimonial__logos__buttons">
          <Button
            {...{
              variant: "blue",
              scrollTarget: "#accuracy",
              text: "Find out more",
            }}
          />

          <Button
            {...{
              variant: "transparent",
              link: "/walkthrough",
              text: "ecomtrack walkthrough",
            }}
          />
        </div>
      </div>

      <div className="hero__images">
        <img src={desktop} className="desktop" />
        <ThemeDesktop></ThemeDesktop>

        <div className="hero__images__single__icon">
          <img src={shopify__card__icon} className="single__icon" />
        </div>
      </div>
    </Section>

    <Section className="testimonial__logos">
      <h3>
        Trusted by <span className="violet">100+ clients</span> all over
        the&nbsp;world{" "}
      </h3>
      <div className="testimonial__logos__row">
        <img
          src={logo_1}
          data-sal="slide-up"
          data-sal-duration="550"
          data-sal-delay="200"
          data-sal-easing="ease-out"
        />
        <img
          src={logo_2}
          data-sal="slide-up"
          data-sal-duration="550"
          data-sal-delay="300"
          data-sal-easing="ease-out"
        />
        <img
          src={logo_4}
          data-sal="slide-up"
          data-sal-duration="550"
          data-sal-delay="400"
          data-sal-easing="ease-out"
        />
        <img
          src={logo_5}
          data-sal="slide-up"
          data-sal-duration="550"
          data-sal-delay="500"
          data-sal-easing="ease-out"
        />
        <img
          src={logo_3}
          data-sal="slide-up"
          data-sal-duration="550"
          data-sal-delay="600"
          data-sal-easing="ease-out"
        />
        <img
          src={logo_6}
          data-sal="slide-up"
          data-sal-duration="550"
          data-sal-delay="700"
          data-sal-easing="ease-out"
        />
      </div>
    </Section>

    <Section className="accuracy blueRect" id="accuracy" colFlex vCentered>
      <Col
        sal="slide-up"
        duration="550"
        delay="100"
        easing="ease-out"
        size="50">
        <Title
          maintitle="Really accurate tracking"
          subtitle="Scale your online store"></Title>
        <p>
          Since iOS 14 update, getting accurate data became extremely
          challenging.
          <span> But, we have solution for it!</span> Our customers are getting
          100% sales attributed back to the correct ad so they can scale their
          online store fast with certainty.
        </p>
        <p className="bullet">scale the highest performing ads only</p>
        <p className="bullet">turn off quickly unprofitable ads</p>
        <p className="bullet">get the truth of what is really working</p>

        <Button
          {...{
            variant: "blue",
            text: "Learn more",
            link: "/solution",
          }}
        />

        <Button
          {...{
            variant: "transparent",
            text: "Start tracking",
            link: "https://app.ecomtrack.io/register",
          }}
        />
      </Col>

      <Col
        sal="slide-up"
        duration="550"
        delay="400"
        easing="ease-out"
        size="50">
        <img src={ad_revenue} />
      </Col>
    </Section>

    <Section colFlex id="consequences" className="consequences">
      <Col size="50">
        <div className="consequences__sticky">
          <h1>
            Key benefits of <span className="violet">ecomtrack</span>
          </h1>
        </div>
      </Col>
      <Col size="50">
        <div
          className="consequences__item"
          data-sal="slide-up"
          data-sal-duration="550"
          data-sal-delay="500"
          data-sal-easing="ease-out">
          <p className="consequences__item__no">01</p>
          <h3>Attribute 100% of your sales to the correct ad</h3>
          <p>
            Say good-bye to Facebook’s misattribution, over-reporting, or
            under-reporting with our server to server connection.
          </p>
        </div>

        <div
          className="consequences__item"
          data-sal="slide-up"
          data-sal-duration="550"
          data-sal-delay="500"
          data-sal-easing="ease-out">
          <p className="consequences__item__no">02</p>
          <h3>Scale with certainty</h3>
          <p>
            With accurate data, you can finally scale the performing ads with
            confidence and turn off any ads that are not profitable. As a
            result, ecomtrack’s clients grow their businesses faster and more
            profitably.
          </p>
        </div>

        <div
          className="consequences__item"
          data-sal="slide-up"
          data-sal-duration="550"
          data-sal-delay="500"
          data-sal-easing="ease-out">
          <p className="consequences__item__no">03</p>
          <h3>Customisable attribution models</h3>
          <p>
            With us you have freedom to choose the attribution model that suits
            your business the most. From 7-day, 14-day, 28-day attribution
            window to „last click“ attribution model.
          </p>
        </div>

        <div
          className="consequences__item"
          data-sal="slide-up"
          data-sal-duration="550"
          data-sal-delay="500"
          data-sal-easing="ease-out">
          <p className="consequences__item__no">04</p>
          <h3>Easy & fast integration</h3>
          <p>
            ecomtrack offers a seamless integration with Shopify, WooCommerce,
            or we can custom-integrate any platform your business uses.
          </p>
        </div>
      </Col>
    </Section>

    <Section className="noPadding">
      <div className="prizing__ready">
        <Col size="50">
          <h3>Ready to start tracking?</h3>
        </Col>
        <Col size="50">
          <Button
            {...{
              variant: "blue",
              link: "https://app.ecomtrack.io/register",
              text: "Start tracking",
            }}
          />
        </Col>
      </div>
    </Section>

    <Section className="attribution" id="attribution" colFlex reversed>
      <Col className="attribution__text" size="50">
        <Title
          maintitle="Freedom to choose the attribution window."
          subtitle="Choose the attribution model yourself"></Title>

        <p>
          The attribution window was shorten from 28 days to just 7, making it
          difficult especially for higher AOV stores, to evaluate results and
          make the best decisions.
        </p>

        <div
          className="attribution__text__tile"
          sal="slide-up"
          duration="550"
          delay="100"
          easing="ease-out">
          <div>
            <img src={icoChart} alt="What works the best" />
          </div>
          <div>
            <h4>Choose what works the best for you</h4>
            <p>
              We don’t want to put any limits on scaling your business so you
              can choose attribution model that works best for you.
            </p>

            <div className="bulletBox">
              <p className="bulletCheck">7-day attribution window </p>
              <p className="bulletCheck">14-day attribution window</p>
              <p className="bulletCheck">28-day attribution window</p>
              <p className="bulletCheck">last click attribution window</p>
            </div>
          </div>
        </div>

        <div
          className="attribution__text__tile"
          sal="slide-up"
          duration="550"
          delay="100"
          easing="ease-out">
          <div>
            <img src={icoCheck} alt="Select what you prefer" />
          </div>
          <div>
            <h4>Select what you prefer</h4>
            <p>
              According to your business model you may choose the best to get
              correct data.
            </p>
          </div>
        </div>
      </Col>

      <Col size="50" className="attribution__image">
        <img src={attributionWindow} alt="Attribution window" />
        <div className="attribution__image__bar">
          <p className="bar__blue">Attribution window</p>
          <p className="pill">7 days</p>
          <p className="pill">14 days</p>
          <p className="pill">28 days</p>
          <p className="pill">Last click</p>
          <div className="handWrapper">
            <img src={hand} alt="hand" />
          </div>
        </div>
      </Col>
    </Section>

    <Section className="setup" colFlex>
      <Col
        sal="slide-up"
        duration="550"
        delay="100"
        easing="ease-out"
        size="50">
        <Title
          maintitle="Supporting the most used platforms"
          subtitle="Cope with all"></Title>
        <p>
          Currently we support a seamless integration with these platforms.
          Shopify, WooCommerce, MyCashflow and Shoptet. Also we support
          advertising platforms Facebook and Google Ads. Are you missing your
          platform in the list? <Link to="/contact/"> Let us know!</Link>
        </p>

        <Button
          {...{
            variant: "transparent",
            link: "/about",
            text: "See implementation",
            className: "notVisible",
          }}
        />
      </Col>

      <Col
        sal="slide-up"
        duration="550"
        delay="400"
        easing="ease-out"
        size="50">
        <img className="setup__platforms" src={supportedPlatforms} />
      </Col>
      {/*
            <Col size="50">
              <div className="supported__ico">
                <img src={supportedShopify} />
                <div>
                  <h5>Shopify</h5>
                  <p>Supported</p>
                </div>
              </div>

              <div className="supported__ico">
                <img src={supportedWooCommerce} />
                <div>
                  <h5>WooCommerce</h5>
                  <p>Supported</p>
                </div>
              </div>

              <div className="supported__ico">
                <img src={supportedAds} />
                <div>
                  <h5>Facebook Ads</h5>
                  <p>Supported</p>
                </div>
              </div>

              <div className="supported__ico supported__icoNew">
                <img src={supportedNew} />
                <div>
                  <h5>More platforms coming</h5>
                  <p>Let us know, which is best for you</p>
                </div>
              </div>
            </Col>
            */}
    </Section>

    <Section className="blueRect integrate" colFlex>
      <Col
        sal="slide-up"
        duration="550"
        delay="100"
        easing="ease-out"
        size="50">
        <Title
          maintitle="Easy to set up and use. See accurate data within hours!"
          subtitle="Rocket fast start"></Title>
        <p>
          Just copy&paste solution, that starts working immediately, once the
          code is inside your website.
        </p>
        <p className="bulletCheck bulletCheckViolet">
          Takes less than 5 minutes
        </p>
        <p className="bulletCheck bulletCheckViolet">
          No advanced coding skills
        </p>
        <p className="bulletCheck bulletCheckViolet">
          Accurate data within days
        </p>

        <Button
          {...{
            variant: "blue",
            text: "Learn more",
            link: "/solution",
          }}
        />

        <Button
          {...{
            variant: "transparent",
            link: "/integration",
            text: "See implementation",
          }}
        />
      </Col>

      <Col
        sal="slide-up"
        duration="550"
        delay="400"
        easing="ease-out"
        size="50">
        <img className="integrateTheme" src={integrateTheme} />
      </Col>
    </Section>

    <Section className="teams" colFlex vCentered reversed>
      <Col size="50">
        <Title
          maintitle="Cooperate efficiently with your team."
          subtitle="User roles."></Title>
        <p>
          We’ve made it super easy for you to invite your team members so you
          can all collaborate together. For agencies, you can invite your
          clients and manage all of them in one place.
        </p>
        <p className="bullet">You can manage all clients in one place.</p>
        <p className="bullet">Share access with your team members.</p>
        <p className="bullet">Choose different roles for your team</p>

        <Button
          {...{
            variant: "blue",
            text: "Calculate price",
            link: "/pricing",
          }}
        />

        <Button
          {...{
            variant: "transparent",
            link: "https://app.ecomtrack.io/register",
            text: "Start tracking",
          }}
        />
      </Col>

      <Col className="teams__bg" size="50"></Col>
    </Section>

    <Section>
      <div className="boost">
        <Col size="50">
          <h2>Boost your store today!</h2>
        </Col>
        <Col size="50">
          <Button
            {...{
              variant: "blue",
              link: "https://app.ecomtrack.io/register",
              text: "Start tracking",
            }}
          />
        </Col>
      </div>
    </Section>

    <Footer colFlex></Footer>
  </main>
);

export default Home;
