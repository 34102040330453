import React, { ReactChild, ReactChildren, useEffect, useState } from "react";
import "./ThemeDesktop.scss"
import ico1 from "@/img/ico/workspaceDesktop/ico1.svg"
import ico2 from "@/img/ico/workspaceDesktop/ico2.svg"
import ico3 from "@/img/ico/workspaceDesktop/ico3.svg"
import ico4 from "@/img/ico/workspaceDesktop/ico4.svg"
import dots from "@/img/ico/dots.svg"
import logo from "@/img/logo.svg"

import upperBar from "@/img/ico/workspaceDesktop/upperBar.svg"
import icoFb from "@/img/ico/workspaceDesktop/icoFb.svg"
import icoIg from "@/img/ico/workspaceDesktop/icoIg.svg"
import icoOrg from "@/img/ico/workspaceDesktop/icoOrg.svg"
import icoPin from "@/img/ico/workspaceDesktop/icoPin.svg"
import icoAds from "@/img/ico/workspaceDesktop/icoAds.svg"

import icoShop from "@/img/ico/workspaceDesktop/icoShop.svg"
import icoCoin from "@/img/ico/workspaceDesktop/icoCoin.svg"
import icoSpend from "@/img/ico/workspaceDesktop/icoSpend.svg"
import icoConversion from "@/img/ico/workspaceDesktop/icoConversion.svg"

import chart from "@/img/ico/workspaceDesktop/chart.svg"
import triangle from "@/img/ico/workspaceDesktop/triangle.svg"
import triangleRed from "@/img/ico/workspaceDesktop/triangleRed.svg"

interface ThemeDesktop {
  className?: string
}
const ThemeDesktop = ({
  className
} : ThemeDesktop) => {

  const [isHeroScrolled, setIsHeroScrolled] = useState(false)

    useEffect(() => {
      document.addEventListener("scroll", handleScroll)
      return () => {
        document.removeEventListener("scroll", handleScroll)
      }
    }, [])

    let handleScroll = () => {
      if (typeof window !== "undefined") {
        if (window.innerWidth > 1200){
          if (window.scrollY > 150) {
            setIsHeroScrolled(false)
          } else {
            setIsHeroScrolled(false)
          }
        } else {
        }
      }
    }

  return (
    <div className={`${className} ${isHeroScrolled ? "ThemeDesktopScrolled" : ""} ThemeDesktop`}>

      <div className="ThemeDesktop__side">
        <img src={dots} className="ThemeDesktop__dots" />
        <div className="ThemeDesktop__logo"><img src={logo} /></div>
        <p className="selected"><img src={ico1} /> Dashboard</p>
        <p><img src={ico2} /> Campaigns</p>
        <p><img src={ico3} /> Integration</p>
        <p><img src={ico4} /> Workspace setup</p>
        <div className="ThemeDesktop__chart">
          <div className="ThemeDesktop__chart__texts">
            <p>Plan usage</p>
            <p>208k / 250k</p>
          </div>
          <div className="ThemeDesktop__chart__line"></div>
          <div className="ThemeDesktop__chart__fill"></div>
        </div>
      </div>

      <div className="ThemeDesktop__main">
        <img className="ThemeDesktop__main__upperBar" src={upperBar} />

        <div className="ThemeDesktop__col__wrap">

        <div className="ThemeDesktop__col">
          <div className="col__card">

            <div className="col__card__row head__row">
              <p className="table__text">Src</p>
              <p className="table__text">Name</p>
              <p className="table__text">ROAS</p>
              <p className="table__text">ROAS Ads</p>
              <p className="table__text">Revenue</p>
              <p className="table__text">Revenue Ads</p>
            </div>

            <div className="col__card__row">
              <img src={icoIg} />
              <p className="table__text">IG_stories_Xmas</p>
              <p className="table__text">3.89</p>
              <p className="table__text">2.56</p>
              <p className="table__text">$876.24</p>
              <p className="table__text">$1 092.24</p>
            </div>

            <div className="col__card__row">
              <img src={icoPin} />
              <p className="table__text">Pinterest Save&Store</p>
              <p className="table__text">1.85</p>
              <p className="table__text">0.89</p>
              <p className="table__text table__text__negative">$867.11</p>
              <p className="table__text table__text__negative">$649.28</p>
            </div>

            <div className="col__card__row zoomLine">
              <img src={icoPin} />
              <p className="table__text">Pinterest Heart Challenge</p>
              <p className="table__text">2.34</p>
              <p className="table__text">1.89</p>
              <p className="table__text">$125.21</p>
              <p className="table__text">$298.35</p>
            </div>

            <div className="col__card__row">
              <img src={icoOrg} />
              <p className="table__text">Organic traffic</p>
              <p className="table__text">2.23</p>
              <p className="table__text">1.72</p>
              <p className="table__text">$1125.48</p>
              <p className="table__text">$962.48</p>
            </div>

            <div className="col__card__row">
              <img src={icoFb} />
              <p className="table__text">COLD: End of Summer Sale</p>
              <p className="table__text">1.78</p>
              <p className="table__text">1.32</p>
              <p className="table__text">$569.17</p>
              <p className="table__text">$726.16</p>
            </div>

            <div className="col__card__row">
              <img src={icoAds} />
              <p className="table__text">Retargeting visitors</p>
              <p className="table__text">2.01</p>
              <p className="table__text">2.09</p>
              <p className="table__text">$179.29</p>
              <p className="table__text">$302.47</p>
            </div>

            <div className="col__card__row">
              <img src={icoPin} />
              <p className="table__text">Pinterest Save&Store</p>
              <p className="table__text">0.02</p>
              <p className="table__text">0.13</p>
              <p className="table__text">$20.02</p>
              <p className="table__text">$12.67</p>
            </div>

            <div className="col__card__row">
              <img src={icoPin} />
              <p className="table__text">Pinterest Heart Challenge</p>
              <p className="table__text">0.49</p>
              <p className="table__text">0.44</p>
              <p className="table__text">$235.12</p>
              <p className="table__text">$178.96</p>
            </div>

            <div className="col__card__row">
              <img src={icoFb} />
              <p className="table__text">BOF: 14D Abandoned Carts</p>
              <p className="table__text">1.05</p>
              <p className="table__text">1.39</p>
              <p className="table__text">$364.12</p>
              <p className="table__text">$229.46</p>
            </div>

            <div className="col__card__row">
              <img src={icoFb} />
              <p className="table__text">TOF: US only</p>
              <p className="table__text">2.31</p>
              <p className="table__text">2.19</p>
              <p className="table__text table__text__negative">$705.07</p>
              <p className="table__text table__text__negative">$769.24</p>
            </div>

            <div className="col__card__row">
              <img src={icoAds} />
              <p className="table__text">Google Shopping</p>
              <p className="table__text">1.48</p>
              <p className="table__text">2.11</p>
              <p className="table__text">$923.68</p>
              <p className="table__text">$883.46</p>
            </div>

            <div className="col__card__row">
              <img src={icoFb} />
              <p className="table__text">TOF: US + Canada</p>
              <p className="table__text">1.38</p>
              <p className="table__text">1.45</p>
              <p className="table__text">$197.97</p>
              <p className="table__text">$184.76</p>
            </div>

          </div>
        </div>
        <div className="ThemeDesktop__col">
          <div className="col__card__blue">
            <div className="col__card__row">
              <img src={icoCoin} />
              <p className="middle__text">$130 795,8<span>Revenue</span></p>
              <p className="right__text"><span>14.2%</span><img className="triangle" src={triangle} /></p>
            </div>
            <div className="col__card__row">
              <img src={icoCoin} />
              <p className="middle__text">1 283<span>Number of orders</span></p>
              <p className="right__text"><span>10.2%</span><img className="triangle" src={triangle} /></p>
            </div>
            <div className="col__card__row">
              <img src={icoSpend} />
              <p className="middle__text">$102,29<span>Average order value</span></p>
              <p className="right__text"><span>2.11%</span><img className="triangle" src={triangle} /></p>
            </div>
            <div className="col__card__row">
              <img src={icoConversion} />
              <p className="middle__text">$15 845,9<span>Total spend</span></p>
              <p className="right__text"><span>3.11%</span><img className="triangle" src={triangle} /></p>
            </div>
            <div className="col__card__row">
              <img src={icoShop} />
              <p className="middle__text">$98 276,8<span>Revenue from ads</span></p>
              <p className="right__text"><span>11.05%</span><img className="triangle" src={triangle} /></p>
            </div>
          </div>

          <div className="col__card">
              <div className="col__card__row">
                <img className="chart" src={chart} />
              </div>
              <div className="col__card__row">
                <p>Jul  22</p>
                <p>Jul  23</p>
                <p>Jul  24</p>
                <p>Jul  25</p>
                <p>Jul  26</p>
                <p>Jul  27</p>
                <p>Jul  28</p>
              </div>
            </div>


        </div>
      </div>
    </div>

    </div>
  )
};

export default ThemeDesktop;
